import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-subscription'>
        <div className='footer-logo'>
        <img src={process.env.PUBLIC_URL + '/assets/lrm-menu.png'} alt="Logo" className="logo-image" />
      </div>
      </div>
      <div className='footer-links'>
        <a href="/">HOME</a>
        <a href="/videos">COMIDIANS</a>
        <a href="/shows">SHOWS</a>
        <a href="/contact">CONTACT</a>
        <a href="#presskit">PRESS KIT</a>
      </div>
      <div className='footer-social'>
      <a href="https://www.facebook.com/profile.php?id=61552184496950" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
        <a href="https://www.instagram.com/lrmmanagement" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
        <a href="https://www.threads.net/@lrmmanagement" target="_blank" rel="noopener noreferrer"><i className="fas fa-at" /></a>
      </div>
      <div className='footer-bottom'>
        <div className='footer-bottom-links'>
          <a href="https://eugenioespinoza.com">Developed by Eugenio Espinoza</a>
        </div>
        <p className='footer-copyright'>Copyright © 2020 LRM Management. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;

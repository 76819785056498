import React from 'react';
import Artists from '../containers/Artists/Artists';
import Footer from '../containers/Footer/Footer';

  
const ArtistsPage = () => {
  return (
    <div>
      <Artists />
      <Footer />
    </div>
  );
};
  
export default ArtistsPage;
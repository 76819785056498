import React from "react";
import "./AboutUs.css";
import kintosolImage from '../../imgs/lrm-office.jpg';

const AboutUsSection = () => {
  return (
    <div className="about-us-section">
      <div className="about-content">
        {/* Left Image Section */}
        <div className="about-image">
          <img src={kintosolImage} alt="About Us" />
        </div>

        {/* Right Text Section */}
        <div className="about-text">
          <h2>ABOUT US</h2>
          <p>
            LRM is where humor meets success. Our team of talented comedians delivers unforgettable performances that leave audiences laughing and inspired. We believe in using humor to bring people together, creating a platform where both comedians and their audiences experience the joy of shared moments.
          </p>

          {/* "Read More About Us" Button */}
          <div className="read-more">
            <a href="/about" className="read-more-btn">Read More About Us</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
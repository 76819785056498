import React, { useEffect, useState } from 'react';
import '../../Artists/ArtistsProfile.css';
import Oscarmimg from "../../../imgs/artists-imgs/oscarm-pro.png";
import Oscarmbg from '../../../imgs/artists-imgs/oscarm-bgbanner.jpg';
import { FaInstagram, FaFacebook, FaYoutube, FaTwitter, FaTiktok  } from 'react-icons/fa';

function SocialCounter({ targetNumber }) {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    const duration = 1500; // Duration of the animation in ms
    const intervalTime = 50; // Interval at which the number increments
    const increment = Math.ceil(targetNumber / (duration / intervalTime)); // Calculate increment per interval

    const interval = setInterval(() => {
      setCurrentNumber((prevNumber) => {
        if (prevNumber + increment >= targetNumber) {
          clearInterval(interval);
          return targetNumber; // Ensure it stops at the final number
        }
        return prevNumber + increment;
      });
    }, intervalTime);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [targetNumber]);

  return <span>{currentNumber.toLocaleString()}</span>; // Format number with commas
}

function JerryGPage() {
  const [isExpanded, setIsExpanded] = useState(false);

  const followerCounts = {
    twitter: 8000,     // Converted numbers into actual integers for animation
    facebook: 831000,
    instagram: 627000,
    youtube: 124000,
    tiktok:  1200000,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleBio = () => {
    setIsExpanded(!isExpanded);
  };

  const containerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url(${Oscarmbg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  };

  return (
    <div className="artists-bg" style={containerStyle}>
      <div className="artist-detail">
        <div className="artist-left">
          <img src={Oscarmimg} alt="Jerry Garcia" className="artistpro-image" />
        </div>
        <div className="artist-right">
          <h1 className="artist-name">OSCAR MIRANDA</h1>

          {/* Social Media Follower Counter */}
          <div className="follower-section-compact">
            <div className="follower-box-compact facebook">
              <FaFacebook className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.facebook} />
              </div>
              <div className="follower-text-compact">Fans</div>
            </div>

            <div className="follower-box-compact twitter">
              <FaTwitter className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.twitter} />
              </div>
              <div className="follower-text-compact">Followers</div>
            </div>

            <div className="follower-box-compact instagram">
              <FaInstagram className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.instagram} />
              </div>
              <div className="follower-text-compact">Followers</div>
            </div>

            <div className="follower-box-compact youtube">
              <FaYoutube className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.youtube} />
              </div>
              <div className="follower-text-compact">Subscribers</div>
            </div>

            <div className="follower-box-compact tiktok">
              <FaTiktok className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.tiktok} />
              </div>
              <div className="follower-text-compact">Followers</div>
            </div>
          </div>

          <p className={`artists-bio ${isExpanded ? "expanded" : "collapsed"}`}>
          Oscar Miranda is a multifaceted entertainer, excelling as a YouTuber, TikToker, comedian, and actor. With a career spanning several platforms, Oscar has captured the attention of millions through his engaging comedic content and authentic connection with his audience. His comedic sketches on social media have garnered him a massive following, making him one of the standout creators in the digital space.

In addition to his online success, Oscar has ventured into the world of acting, making notable appearances in productions like Self Tape (2023), The Real Bros of Simi Valley: The Movie (2024), and the popular series The Real Bros of Simi Valley (2017). His versatility, from delivering sharp humor online to performing on screen, showcases his diverse talent and creative passion.

Whether he’s sharing hilarious skits or captivating audiences on the big screen, Oscar Miranda continues to rise as a dynamic force in both the digital and entertainment worlds.
          </p>

          <button className="toggle-bio-button" onClick={toggleBio}>
            {isExpanded ? "Show Less" : "Read More"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default JerryGPage;
import React, { useEffect, useState } from "react";
import "../../Artists/ArtistsProfile.css";
import concreteimg from "../../../imgs/artists-imgs/concrete-pro.png";
import concretebg from "../../../imgs/artists-imgs/concrete-bgbanner.jpg"
import { FaTwitter, FaFacebook, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa';

function SocialCounter({ targetNumber }) {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    const duration = 1500; // Duration of the animation in ms
    const intervalTime = 50; // Interval at which the number increments
    const increment = Math.ceil(targetNumber / (duration / intervalTime)); // Calculate increment per interval

    const interval = setInterval(() => {
      setCurrentNumber((prevNumber) => {
        if (prevNumber + increment >= targetNumber) {
          clearInterval(interval);
          return targetNumber; // Ensure it stops at the final number
        }
        return prevNumber + increment;
      });
    }, intervalTime);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [targetNumber]);

  return <span>{currentNumber.toLocaleString()}</span>; // Format number with commas
}

function ConcretePage() {
  const followerCounts = {
    twitter: 154000,     // Converted numbers into actual integers for animation
    facebook: 82000,
    instagram: 717000,
    youtube: 15400,
    tiktok: 594800,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url(${concretebg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  };


  return (
    <div className="artists-bg" style={containerStyle}>
      
    <div className="artist-detail">
      <div className="artist-left">
        <img src={concreteimg} alt="Concrete" className="artistpro-image" />
      </div>
      <div className="artist-right">
        <h1 className="artist-name">Concrete</h1>

        {/* Social Media Follower Counter */}
        <div className="follower-section-compact">
          <div className="follower-box-compact facebook">
            <FaFacebook className="social-icon-compact" />
            <div className="follower-count-compact">
              <SocialCounter targetNumber={followerCounts.facebook} />
            </div>
            <div className="follower-text-compact">Fans</div>
          </div>

          <div className="follower-box-compact twitter">
            <FaTwitter className="social-icon-compact" />
            <div className="follower-count-compact">
              <SocialCounter targetNumber={followerCounts.twitter} />
            </div>
            <div className="follower-text-compact">Followers</div>
          </div>

          <div className="follower-box-compact instagram">
            <FaInstagram className="social-icon-compact" />
            <div className="follower-count-compact">
              <SocialCounter targetNumber={followerCounts.instagram} />
            </div>
            <div className="follower-text-compact">Followers</div>
          </div>

          <div className="follower-box-compact youtube">
            <FaYoutube className="social-icon-compact" />
            <div className="follower-count-compact">
              <SocialCounter targetNumber={followerCounts.youtube} />
            </div>
            <div className="follower-text-compact">Subscribers</div>
          </div>

          <div className="follower-box-compact tiktok">
            <FaTiktok className="social-icon-compact" />
            <div className="follower-count-compact">
              <SocialCounter targetNumber={followerCounts.tiktok} />
            </div>
            <div className="follower-text-compact">Followers</div>
          </div>
        </div>

        <p className="artists-bio">
          From an early childhood that led him through every urban town in the
          San Fernando Valley to finally calling Sylmar, California his home,
          Cristian Gutierrez now better known as Concrete, has the makings of
          an upbringing that to this day solidify his eclectic creative
          prowess in a city that demands for creativity. Concrete was able to
          perfect his trade, create his own brand, and eventually end up
          having two radio records, "Certified Kush" and "We Can Make it Rain"
          on the Los Angeles based major Hip-Hop radio station Power 106 and
          35 other major stations across the country. Sharing the stage with
          luminaries like DJ Quick, G-Unit, Cypress Hill, Fat Joe, and Kid
          Frost, Concrete's journey in the music scene reached impressive
          heights. Collaborations with Hip-Hop heavyweights such as Krayzie
          Bone, Lazie Bone, and members of The Outlaws solidified his position
          in the industry. Transitioning seamlessly, Concrete has shifted his
          focus to the world of comedy, routinely captivating audiences and
          selling out stand-up comedy clubs. His social media platforms are
          ablaze with a consistent flow of new and engaging content,
          showcasing his versatility and ensuring that his creative flame
          continues to burn brightly.
        </p>
      </div>
    </div>
    </div>
  );
}

export default ConcretePage;
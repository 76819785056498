import React, { useEffect, useState } from "react";
import './ShowsSection.css';

const ShowsSection = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);  // New state for loading

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch("https://corsproxy.io/?https://linktr.ee/lrmmanagementinc");
        const html = await response.text();

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const scriptTag = doc.querySelector("#__NEXT_DATA__");

        if (scriptTag) {
          const jsonData = JSON.parse(scriptTag.innerHTML);

          const links = (jsonData && jsonData.props && jsonData.props.pageProps && jsonData.props.pageProps.account && jsonData.props.pageProps.account.links) || [];

          const dateRegex = /\b(January|February|March|April|May|June|July|August|September|October|November|December)\b \d{1,2}, \d{4}/;

          const upcomingEvents = links.filter(link => dateRegex.test(link.title));

          setEvents(upcomingEvents);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);  // Hide loading spinner when done
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className="event-list">
      {loading ? (
        <div className="loading-spinner"></div>  // Add loading indicator
      ) : events.length > 0 ? (
        events.map((event, index) => {
          const [cityState, dateTime, comedian] = event.title.split(" - ");

          return (
            <div className="event-item" key={index}>
              <h3>{cityState}</h3>
              <p>{dateTime}</p>
              <p>{comedian}</p>
              <a href={event.url} target="_blank" rel="noopener noreferrer">
                Get Tickets
              </a>
            </div>
          );
        })
      ) : (
        <p>No events available.</p>
      )}
    </div>
  );
};

export default ShowsSection;
import React from 'react';
import YTVideos from '../containers/YTVideos/YTVideos'
import Footer from '../containers/Footer/Footer';
  
const Videos = () => {
  return (
    <div>
      <YTVideos />
      <Footer />
    </div>
  );
};
  
export default Videos;
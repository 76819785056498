import React from 'react';
import Contact from '../containers/Contact/ContactSection'
import Footer from '../containers/Footer/Footer';
  
const ContactPage = () => {
  return (
    <div>
      <Contact/>
      <Footer />
    </div>
  );
};
  
export default ContactPage;
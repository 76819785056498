import React, { useEffect}  from 'react';
import '../App.css';
import HeroSection from '../containers/Hero/MainHeroSection';
// import MobileHeroSection from '../containers/Hero/MobileHeroSection';
import AboutUsSection from '../containers/About/AboutUs';
import Footer from '../containers/Footer/Footer';
import Artists from '../containers/Artists/Artists';


function Home() {

    useEffect(() => {

        // const previousOverflow = document.body.style.overflow;
    
        // Disable scrolling
        // document.body.style.overflow = 'hidden';
    
        return () => {
          // Re-enable scrolling when the component is unmounted
          // document.body.style.overflow = previousOverflow;
        };
      }, []);
    
      return (
        <>
          {/* {window.innerWidth > 800 ? <HeroSection />  : <MobileHeroSection /> } */}
          <HeroSection /> 
          <AboutUsSection/>
          <Artists />
          <Footer />
        </>
        
      );
    }

export default Home;





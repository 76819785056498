import React, { useState, useEffect } from 'react';
import '../../App.css';
import './MainHeroSection.css';

function HeroSection() {
    // Slideshow images array
    const slides = [
        process.env.PUBLIC_URL + '/assets/slide1.jpg',
        process.env.PUBLIC_URL + '/assets/slide2.jpg',
        process.env.PUBLIC_URL + '/assets/slide1.jpg'
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const [progress, setProgress] = useState(0);

    // Automatically change slides every 5 seconds
   useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
            setProgress(0); // Reset progress bar on slide change
        }, 15000); // 15-second interval

        return () => clearInterval(slideInterval); // Cleanup interval on unmount
    }, [slides.length]);

    // Progress bar animation (increments every 150ms)
    useEffect(() => {
        const progressInterval = setInterval(() => {
            setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 1 : prevProgress));
        }, 150); // Progress increments every 150ms (100 increments over 15 seconds)

        return () => clearInterval(progressInterval); // Cleanup progress interval on unmount
    }, []);

    return (
        <div className='hero-wrap'>
            <div className='hero-container'>
                <div className="slide-show">
                    {/* Slideshow images */}
                    {slides.map((slide, index) => (
                        <div
                            key={index}
                            className={`slide ${index === currentSlide ? 'active' : ''}`}
                            style={{
                                backgroundImage: `url(${slide})`,
                                display: index === currentSlide ? 'block' : 'none'
                            }}
                        ></div>
                    ))}
                </div>

                {/* Progress bar */}
                <div className="progress-bar">
                    <div
                        className="progress"
                        style={{ width: `${progress}%` }}
                    ></div>
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
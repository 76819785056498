import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import "../../App.css";
import './YTVideos.css';

const CORS_PROXY = 'https://api.allorigins.win/get?url=';
const RSS_FEED_URL = 'https://www.youtube.com/feeds/videos.xml?channel_id=UCwUaKGs4fT3MYmzX6HDuZiw';

const getVideosFromChannel = async () => {
  try {
    const response = await axios.get(CORS_PROXY + encodeURIComponent(RSS_FEED_URL));
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(response.data.contents, "text/xml");
    const items = xmlDoc.getElementsByTagName("entry");
    const videos = Array.from(items).map(item => {
      const videoId = item.getElementsByTagName("yt:videoId")[0].textContent;
      return {
        title: item.getElementsByTagName("title")[0].textContent,
        link: item.getElementsByTagName("link")[0].getAttribute("href"),
        thumbnail: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`, // Attempt to use highest resolution
      };
    });
    return videos;
  } catch (error) {
    console.error("Error fetching videos:", error.response ? error.response.data : error.message);
    return [];
  }
};

function YTVideos() {
  const [videos, setVideos] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchVideos = async () => {
      const videoData = await getVideosFromChannel();
      console.log("Fetched videos:", videoData); // Log the fetched video data
      setVideos(videoData);
    };
    fetchVideos();
  }, []);

  const fetchMoreData = () => {
    setPage(page + 1);
    // Assuming each page loads 10 videos, slice accordingly
    const nextVideos = videos.slice(page * 10, (page + 1) * 10);
    if (nextVideos.length === 0) {
      setHasMore(false);
      return;
    }
    setVideos([...videos, ...nextVideos]);
  };

  const handleThumbnailError = (event) => {
    event.target.src = event.target.src.replace('maxresdefault.jpg', 'hqdefault.jpg');
  };

  return (
    <div className="video-page-container">
      {videos.length > 0 ? (
        <>
          <div className="main-video">
            <a
              href={videos[0].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={videos[0].thumbnail}
                alt={videos[0].title}
                className="main-video-thumbnail"
                onError={handleThumbnailError}
              />
              <div className="main-video-gradient"></div>
              <div className="main-video-overlay">
                <h1 className="main-video-title">{videos[0].title}</h1>
                <div className="main-video-play-icon">
                  <i className="fas fa-play-circle"></i>
                </div>
              </div>
            </a>
          </div>
          <section className="video-section">
          <InfiniteScroll
              dataLength={videos.length} // This is important field to render the next data
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                  <p className="loading-text">Loading...</p>
                </div>
              }
              endMessage={<p className="end-message">No more videos to show</p>}
            >
              <div className="video-gallery">
                {videos.slice(1).map((video, index) => (
                  <div key={index} className="video-container">
                    <a
                      href={video.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={video.thumbnail}
                        alt={video.title}
                        className="video-thumbnail"
                        onError={handleThumbnailError}
                      />
                      <div className="video-gradient"></div>
                      <div className="video-title">{video.title}</div>
                    </a>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </section>
        </>
      ) : (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p className="loading-text">Loading videos...</p>
        </div>
      )}
    </div>
  );
}

export default YTVideos;
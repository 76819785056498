import React from 'react';
import Footer from '../containers/Footer/Footer';
import Doknowsworldpage from '../containers/Artists/Doknowsworld/doknowsworld';

const doknowsworldPage = () => {
  return (
    <div>
    <Doknowsworldpage />
    <Footer />
    </div>
  );
};
  
export default doknowsworldPage;
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import './App.css';
import Home from './pages/Home';
import Videos from './pages/Videos';
import Artists from './pages/Artists';
import ConcretePage from './pages/Concrete';
import JerryGPage from './pages/JerryG';
import doknowsworldPage from './pages/Doknowsworld';
import oscarmPage from './pages/OscarM';
import Shows from './pages/Shows';
import Contact from './pages/Contact';

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/artists' exact component={Artists} />
        <Route path='/Concrete' exact component={ConcretePage} />
        <Route path='/JerryG' exact component={JerryGPage} />
        <Route path='/Doknowsworld' exact component={doknowsworldPage} />
        <Route path='/OscarM' exact component={oscarmPage} />
        <Route path='/videos' exact component={Videos} />
        <Route path='/shows' exact component={Shows} />
        <Route path='/contact' exact component={Contact} />
      </Switch>
    </Router>
  );
}

export default App;
import React from 'react';
import Concretepage from '../containers/Artists/Concrete/concrete'
import Footer from '../containers/Footer/Footer';

const ConcretePage = () => {
  return (
    <div>
      <Concretepage/>
      <Footer/>
    </div>
  );
};
  
export default ConcretePage;
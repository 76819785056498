import React from 'react';
import ShowsSection from '../containers/Shows/ShowsSection';
import Footer from '../containers/Footer/Footer';
  
const Shows = () => {
  return (
    <div>
      <ShowsSection />
      <Footer />
    </div>
  );
};
  
export default Shows;
import React, { useEffect, useState } from 'react';
import '../../Artists/ArtistsProfile.css';
import JerryGimg from "../../../imgs/artists-imgs/jerryg-pro.png";
import JerryGbg from '../../../imgs/artists-imgs/jerrryg-bgbanner.jpg';
import { FaInstagram, FaFacebook, FaYoutube, FaTwitter, FaTiktok  } from 'react-icons/fa';

function SocialCounter({ targetNumber }) {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    const duration = 1500; // Duration of the animation in ms
    const intervalTime = 50; // Interval at which the number increments
    const increment = Math.ceil(targetNumber / (duration / intervalTime)); // Calculate increment per interval

    const interval = setInterval(() => {
      setCurrentNumber((prevNumber) => {
        if (prevNumber + increment >= targetNumber) {
          clearInterval(interval);
          return targetNumber; // Ensure it stops at the final number
        }
        return prevNumber + increment;
      });
    }, intervalTime);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [targetNumber]);

  return <span>{currentNumber.toLocaleString()}</span>; // Format number with commas
}

function JerryGPage() {
  const [isExpanded, setIsExpanded] = useState(false);

  const followerCounts = {
    twitter: 154000,     // Converted numbers into actual integers for animation
    facebook: 82000,
    instagram: 717000,
    youtube: 15400,
    tiktok: 594800,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleBio = () => {
    setIsExpanded(!isExpanded);
  };

  const containerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url(${JerryGbg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  };

  return (
    <div className="artists-bg" style={containerStyle}>
      <div className="artist-detail">
        <div className="artist-left">
          <img src={JerryGimg} alt="Jerry Garcia" className="artistpro-image" />
        </div>
        <div className="artist-right">
          <h1 className="artist-name">JERRY GARCIA</h1>

          {/* Social Media Follower Counter */}
          <div className="follower-section-compact">
            <div className="follower-box-compact facebook">
              <FaFacebook className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.facebook} />
              </div>
              <div className="follower-text-compact">Fans</div>
            </div>

            <div className="follower-box-compact twitter">
              <FaTwitter className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.twitter} />
              </div>
              <div className="follower-text-compact">Followers</div>
            </div>

            <div className="follower-box-compact instagram">
              <FaInstagram className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.instagram} />
              </div>
              <div className="follower-text-compact">Followers</div>
            </div>

            <div className="follower-box-compact youtube">
              <FaYoutube className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.youtube} />
              </div>
              <div className="follower-text-compact">Subscribers</div>
            </div>

            <div className="follower-box-compact tiktok">
              <FaTiktok className="social-icon-compact" />
              <div className="follower-count-compact">
                <SocialCounter targetNumber={followerCounts.tiktok} />
              </div>
              <div className="follower-text-compact">Followers</div>
            </div>
          </div>

          <p className={`artists-bio ${isExpanded ? "expanded" : "collapsed"}`}>
          Jerry Garcia has stormed his way onto the national comedy scene as one of the top-rated comedians and is truly a must-see performer.
        A father of three boys, Jerry allows us to enter his world and laugh hysterically with his charm, witty puns, and punchlines using his young, 
        hip, real-life flavor that easily crosses over to all audiences. His views on life, relationships, 
        his modest upbringings, and everyday struggles captivate audiences from all walks of life. No matter where you’re from, the jokes hit home.

        Jerry’s presence, strong delivery, and likability on stage help him connect and interact with audience members in the most memorable way. 
        Fans leave Jerry’s performances with the feeling of having met a new funny friend.

        Jerry Garcia's credits continue to grow year after year, 
        beginning in 2016 with Fox's Laughs comedy series. In 2017, 
        Jerry made his Netflix debut co-starring in Chingo Bling’s “They Can’t Deport Us All” Comedy Special. 
        In 2018, Jerry continued to rise as he made his HBO debut on "Entre Nos" Comedy Series. 
        A stand-alone HBO Max comedy special appropriately titled "It's Not My Weekend" premiered in the summer of 2019. 
        Earlier in 2019, Jerry crossed over to acting in 2023 Guest Starring on George Lopez sitcom Lopez vs Lopez.

        <h3>OTHER CREDITS:</h3>
        <ul>
          <li>2015 Armed Forces Ent: Turkey, Jordan, Syria, and Greece.</li>
          <li>2016 San Diego Comedy Festival; Best Comedian Award.</li>
          <li>2017 LA’s Funniest Comic Contest: First Place.</li>
          <li>2017 They Can’t Deport Us All – Netflix.</li>
          <li>2019 Ploey Animated Film.</li>
          <li>2019 Entre Nos Comedy Special HBO MAX.</li>
          <li>2020 “It’s Not My Weekend” HBO MAX Comedy Special.</li>
          <li>2021 Imagen Award Nominee (Best Reality – Comedy Special).</li>
          <li>2021 King of Downey; Sitcom (Pilot).</li>
          <li>2023 Sitcom; Lopez vs Lopez – NBC/Peacock.</li>
        </ul>

        <h3>PRESENT:</h3>
        <ul>
          <li>Latin Kings of Comedy Tour.</li>
          <li>American Wannabes Podcast.</li>
          <li>Tragos Amargos Podcast.</li>
          <li>Los Toxicos Comedy Tour.</li>
        </ul>
          </p>

          <button className="toggle-bio-button" onClick={toggleBio}>
            {isExpanded ? "Show Less" : "Read More"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default JerryGPage;